import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import ProductSwiper from '../components/ProductSwiper';

import '../css/templates/_sector-template.scss';
import SEO from '../components/SEO';

export default function SectorTemplate({ data }) {
  const { title, mainImage, residential, desc, services, short } =
    data.sanitySector;

  const { contact } = data.sanitySiteInfo;
  const ph1 = contact.phone.slice(0, 2);
  const ph2 = contact.phone.slice(2, 5);
  const ph3 = contact.phone.slice(5, 8);
  const ph4 = contact.phone.slice(8, contact.phone.length);
  const fullPhone = `${ph1} (${ph2}) ${ph3} - ${ph4}`;

  const serializers = {
    marks: {
      /* eslint-disable */
      link: ({ children, mark }) => (
        <a href={mark.href} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      /* eslint-enable */
    },
  };

  return (
    <Layout transparentNav>
      <SEO title={title} description={short} />

      <div className="sector-template">
        {/* Hero section */}
        <Hero
          type="type-2"
          heroImg={mainImage.asset.gatsbyImageData}
          heroImgAlt={mainImage.alt}
          h5={residential ? 'Residential' : 'Commercial'}
          h1={title}
        />

        {/* About section */}
        <section className="about">
          <div className="wrapper">
            <div className="content">
              <p className="big">{desc}</p>
              <button className="open-contact-modal" type="button">
                Book a free consultation
              </button>
              <div className="call-us">
                <p>Want to chat about our services? Call us at</p>
                <a href={`tel:${contact.phone}`} className="underline white">
                  {fullPhone}
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Services listing */}
        <section className="all-services">
          <div className="wrapper">
            {services.map((service, i) => (
              <div className="service" key={i}>
                <div className="content">
                  <h3>{service.title}</h3>
                  <PortableText
                    blocks={service._rawDesc}
                    serializers={serializers}
                  />
                </div>
                <div className="service-image">
                  <GatsbyImage
                    image={service.image.asset.gatsbyImageData}
                    alt={service.image.alt}
                    className="img"
                  />
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Products slider */}
        <ProductSwiper />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query GetSingleSector($slug: String) {
    sanitySector(slug: { current: { eq: $slug } }) {
      title
      short
      mainImage {
        alt
        asset {
          gatsbyImageData
        }
      }
      residential
      desc
      services {
        title
        image {
          asset {
            gatsbyImageData
          }
        }
        _rawDesc
      }
    }
    sanitySiteInfo {
      contact {
        address
        email
        phone
      }
    }
  }
`;
